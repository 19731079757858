import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class BusinessService {
  getBusinessClassificationList() {
    return axios
      .get(`${config["apiUrl"]}business/classification`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBusinessAreasList() {
    return axios
      .get(`${config["apiUrl"]}business/businessArea`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBusinessSolutionsList() {
    return axios
      .get(`${config["apiUrl"]}business/solutions`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  saveBusinessClassification(bodyParms) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";
    return axios
      .post(`${config["apiUrl"]}business/classification`, bodyParms, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateBusinessClassification(id, bodyParms) {
    let headers = authHeader();
    headers["Content-Type"] = "multipart/form-data";
    return axios
      .post(`${config["apiUrl"]}business/classification/${id}`, bodyParms, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBusinessClassificationFile(classificationId, fileId) {
    return axios
      .get(
        `${config["apiUrl"]}business/classification/${classificationId}/file/${fileId}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getBusinessClassificationFiles(classificationId) {
    return axios
      .get(
        `${config["apiUrl"]}business/classification/${classificationId}/files`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBusinesToClassify() {
    return axios
      .get(`${config["apiUrl"]}business/classification/toClassify`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countBusinesToClassify() {
    return axios
      .get(`${config["apiUrl"]}business/classification/toClassify/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getBusinesDisclose() {
    return axios
      .get(`${config["apiUrl"]}business/classification/disclose`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countBusinesDisclose() {
    return axios
      .get(`${config["apiUrl"]}business/classification/disclose/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getBusinesToClose() {
    return axios
      .get(`${config["apiUrl"]}business/classification/toClose`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countBusinesToClose() {
    return axios
      .get(`${config["apiUrl"]}business/classification/toClose/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBusinesCaseStudy() {
    return axios
      .get(`${config["apiUrl"]}business/classification/caseStudy`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countBusinesCaseStudy() {
    return axios
      .get(`${config["apiUrl"]}business/classification/caseStudy/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getClassificationList() {
    return axios
      .get(`${config["apiUrl"]}business/classification/list`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  countClassifications() {
    return axios
      .get(`${config["apiUrl"]}business/classification/list/count`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getPortfolio() {
    return axios
      .get(`${config["apiUrl"]}business/portfolio`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getPortfolioByAreas() {
    return axios
      .get(`${config["apiUrl"]}business/portfolio/byAreas`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getPortfolioBySolutions() {
    return axios
      .get(`${config["apiUrl"]}business/portfolio/bySolutions`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getClassification(id) {
    return axios
      .get(`${config["apiUrl"]}business/classification/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getClassificationLogs(id) {
    return axios
      .get(`${config["apiUrl"]}business/classification/${id}/logs`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addBusinessToClassification(businessId, bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}business/classification/${businessId}/addbusineess`,
        bodyParms,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  search(term) {
    return axios
      .get(`${config["apiUrl"]}business/classification/search/${term}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getClassificationBusiness(id) {
    return axios
      .get(`${config["apiUrl"]}business/classification/${id}/busineess`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  removeBusinessFromClassification(busineessId, classificationId) {
    return axios
      .delete(
        `${config["apiUrl"]}business/classification/${classificationId}/busineess/${busineessId}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getBusinessAreas() {
    return axios
      .get(`${config["apiUrl"]}business/businessArea`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createBusinessArea(params) {
    return axios
      .post(`${config["apiUrl"]}business/businessArea/`, params, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateBusinessArea(id, params) {
    return axios
      .patch(`${config["apiUrl"]}business/businessArea/${id}`, params,{
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new BusinessService();
